import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { topLinks } from "../components/Navbar";

function FeaturedSection({
  featuredData: data,
  featuredImage: image
}) {
  const isInternalLink = topLinks.some(e => e.url === data.button.url);
  return (
    <section className="section section--featured has-no-padding-bottom">
      <div className="container grid has-centered">
        <div className="column is-desktop-x-5 is-6">
          <div className="image">
            <GatsbyImage alt="{data.image_alt}"
              image={image} />
          </div>
        </div>
        <div className="column is-desktop-x-5 is-6">
          <div className="card has-no-padding">
            <div className="card__content has-no-padding-top">
              <h2>{data.heading}</h2>
              <p className="has-white-space">{data.info}</p>
            </div>
            {data.button.text &&
              <div className="card__footer">
                {isInternalLink
                  ? <Link to={data.button.url}
                      className="button has-no-margin">{data.button.text}</Link>

                  : <a href={data.button.url}
                      className="button has-no-margin">{data.button.text}</a>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedSection;
