import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { topLinks } from "../components/Navbar";

function HomeHero({
  heroData,
  heroImage
}) {
  const isInternalLink = topLinks.some(e => e.url === heroData.button.url);
  return (
    <div className="hero hero--home-page">
      <div className="hero__content"
        style={{display: 'grid', height: heroData.height+"px"}}>
        <GatsbyImage style={{gridArea: "1/1", height: heroData.height+"px"}}
          alt={heroData.image_alt}
          image={heroImage} />
        <div className="container grid is-vertical"
          style={{gridArea: "1/1", position: "relative"}}>
          <div className="column is-half">
            {heroData.heading &&
              <h1>{heroData.heading}</h1>
            }
          </div>
          <div className="column is-half">
            {heroData.info &&
              <p className="has-white-space">{heroData.info}</p>
            }
          </div>
          {heroData.button.text &&
            <div className="column is-half">
              {isInternalLink
                ? <Link to={heroData.button.url}
                    className="button is-large is-secondary is-outline">{heroData.button.text}</Link>
                : <a href={heroData.button.url}
                    className="button is-large is-secondary is-outline">{heroData.button.text}</a>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default HomeHero;
