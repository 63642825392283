import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { topLinks } from "../components/Navbar";

function createServiceBlock(data) {
  const isInternalLink = topLinks.some(e => e.url === data.url);
  const Tile = (src, text) => {
    return (
      <React.Fragment>
        <GatsbyImage alt=""
          image={data.imageSrc}
          style={{gridArea: "1/1", maxHeight: "250px"}} />
        <div className="overlay"
          style={{gridArea: "1/1", position: "relative"}}>
          <h4>{data.text}</h4>
        </div>
      </React.Fragment>
    )
  };

  return (
    <div className="column is-half is-desktop-one-third"
      key={data.image}>
      {isInternalLink
        ? <Link to={data.url}
          className="image is-tile"
          style={{display: 'grid'}}>
            <Tile />
          </Link>
        : <a href={data.url}
          className="image is-tile"
          style={{display: 'grid'}}>
            <Tile />
          </a>
      }
    </div>
  );
}

function FeaturedServices({
  servicesData
}) {

  return (
    <section className="section feature-bar">
      <div className="container grid has-centered">
        <div className="column is-desktop-x-10 is-full has-no-padding grid">
          {servicesData.map(service => (
            createServiceBlock(service)
          ))}
        </div>
      </div>
    </section>
  )
}

export default FeaturedServices;
