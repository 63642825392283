import React from 'react';
import { Autoplay, Lazy } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { GatsbyImage } from "gatsby-plugin-image";

function createProductSwiperSlide(data) {
  return (
    <SwiperSlide key={data.image}>
      <GatsbyImage alt={data.image_alt}
        image={data.imageSrc}
        className="swiper-lazy" />
      <div className="swiper-lazy-preloader"></div>
    </SwiperSlide>
  );
}

function FeaturedProducts({
  productsData
}) {
  return (
    <section className="section product-slide is-small has-bg-primary has-text-white">
      <div className="container grid">
        <div className="column is-full has-text-center">
          <h2>{productsData.heading}</h2>
        </div>
        <div className="column is-full">
          <Swiper
            modules={[Autoplay, Lazy]}
            spaceBetween={40}
            slidesPerView={2}
            breakpoints={{767: {slidesPerView: 2}, 922: {slidesPerView: 3}, 1200: {slidesPerView: 4}}}
            loop={true}
            lazy={{loadPrevNext: true}}
            autoplay={{delay: 4000, disableOnInteraction: false}}>
            {productsData.product.map(product => (
              createProductSwiperSlide(product)
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default FeaturedProducts;
