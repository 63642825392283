import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from '../components/Layout';
import YAMLData from "../../static/content/home.yml";
import HomeHero from '../components/HomeHero.js';
import FeaturedServices from '../components/FeaturedServices';
import FeaturedSection from '../components/FeaturedSection';
import FeaturedProducts from '../components/FeaturedProducts';
import SEO from "../components/SEO";

const IndexPage = ({pageContext}) => {
  const imageData = useStaticQuery(graphql`
    query {
      banners: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 2000
              )
            }
            extension
            publicURL
          }
        }
      }
      featured: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 700
              )
            }
            extension
            publicURL
          }
        }
      }
      services: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 600
              )
            }
            extension
            publicURL
          }
        }
      }
      products: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 600
              )
            }
            extension
            publicURL
          }
        }
      }
    }
  `);

  const {
    hero,
    featuredSection: featured,
    featuredServices: services,
    featuredProducts: products
  } = YAMLData;
  
  const heroImage = imageData.banners.edges.find(
    heroImage => "/assets/"+heroImage.node.relativePath === hero.image
  );

  const featuredImage = imageData.featured.edges.find(
    featuredImage => "/assets/"+featuredImage.node.relativePath === featured.image
  );

  services.map(service => {
    let serviceImage = imageData.services.edges.find(
      serviceImage => "/assets/"+serviceImage.node.relativePath === service.image
    )
    service.imageSrc = serviceImage.node.childImageSharp.gatsbyImageData;
    return service;
  });

  products.product.map(product => {
    let productImage = imageData.products.edges.find(
      productImage => "/assets/"+productImage.node.relativePath === product.image
    )
    product.imageSrc = productImage.node.childImageSharp.gatsbyImageData;
    return product;
  });

  return (
    <Layout homePage={true}>
      <SEO />
      <HomeHero heroData={hero}
        heroImage={heroImage.node.childImageSharp.gatsbyImageData} />
      <FeaturedSection featuredData={featured}
        featuredImage={featuredImage.node.childImageSharp.gatsbyImageData}/>
      <FeaturedServices servicesData={services} />
      <FeaturedProducts productsData={products} />
    </Layout>
  );
}

export default IndexPage;
